
/* ============================================================================== */
/* ============================================================================== */
/* SETUP ======================================================================== */
/* ============================================================================== */

@charset 'utf-8';

// :root {
// 	--vh: 100vh;
// }

// ########################################################################## //
// VARIABLES

// # FONTS
$sans-serif-stack: var(--sans-serif-stack, sans-serif);
$serif-stack: var(--serif-stack, serif);
$monospace-stack: var(--monospace-stack, monospace);

// # COLOURS
$colour-main: var(--colour-main, #efefef);
$colour-main-translucent: var(--colour-main-translucent, #efefef99);
$colour-main-tint: var(--colour-main-tint, #f6f6f6);
$colour-white: #fff;
$colour-black: #000;

$colour-header-link-background-thispage: var(--colour-header-link-background-thispage, #ffffff);
$colour-header-link-background-hover: var(--colour-header-link-background-hover, #ffffff44);
$colour-home-offering: var(--colour-home-offering, $colour-main);

$colour-cp-highlight: var(--colour-cp-highlight, $colour-main);
$colour-cp-highlight-tint: var(--colour-cp-highlight-tint, $colour-main-tint);

// # IMAGES
$image-background: var(--image-background, none);

// ########################################################################## //
// MIXINS

@mixin cross-browser($property, $value) {
	-webkit-#{$property}: $value;
		 -moz-#{$property}: $value;
			-ms-#{$property}: $value;
					#{$property}: $value;
}

@mixin mpb($margin:0, $padding:0, $border:0) {
		margin: $margin;
		padding: $padding;
		border: $border;
}

// ########################################################################## //

html {
	box-sizing: border-box;
	@include mpb();
	@include cross-browser('font-smoothing', antialiased);
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;

	background-color: $colour-main;
	background-image: $image-background;
	background-size: var(--background-size, 100%);
	background-repeat: var(--background-repeat, repeat-y);
	background-position: var(--background-position, 0 -100px);

	font-family: $serif-stack;
	font-size: 16px;
	color: var(--colour-text, #000000);

	overflow-y: auto;
	overflow-x: hidden;

	min-height: var(--vh);
	max-height: var(--vh);
}

body {
	@include mpb();

	width: 100%;
	max-height: var(--vh);
	min-height: var(--vh);

	display: grid;
	grid-template-rows: 1fr auto;
}

.hide, .hidden {
	display: none !important;
}

.invisible {
	visibility: hidden !important;
}

/* ============================================================================== */
/* ============================================================================== */
/* LOGIN ======================================================================== */
/* ============================================================================== */

main {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;

	width: 100%;
	max-width: 100%;
	height: auto;
	max-height: auto;

	align-items: center;
	justify-content: center;
}

.login {

	text-align: center;
	max-width: calc(100% - 2rem);
	justify-self: center;

	img.logo {
		display: block;
		width: calc(100% - 4rem);
		max-width: 320px;
		margin: 0 auto 4em auto;
	}

	.prompt {
		font-family: $sans-serif-stack;
		font-size: 14px;
		display: inline-block;
		margin-bottom: 10px;
		letter-spacing: 1px;
	}

	input {
		display: inline-block;
		width: 34px;
		margin : 0 0 0 0;
		border: 0;
		padding: 10px;
		font-size: 32px;
		font-family: $monospace-stack;
		font-weight: 700;
		text-align: center;
		opacity: .8;
		z-index: -1 !important;
		text-transform: uppercase;
		background-color: var(--colour-login-input, #fff);

		&:focus {
			outline: none;
			opacity: 1;
		}
	}
	
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	.loader {
		display: none;
		position: relative;
		width: 100%;
		padding: 30px 0;
		margin: -120px auto 0 auto;
		background-color: $colour-main-translucent;
		z-index: 99 !important;

		.elements {
			width: 40px;
			height: 40px;
			position: relative;
			margin: 20px auto;
			
			.circle1, .circle2 {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: #fff;
				opacity: 0.6;
				position: absolute;
				top: 0;
				left: 0;
				
				-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
				animation: sk-bounce 2.0s infinite ease-in-out;
			}

			.circle2 {
				-webkit-animation-delay: -1.0s;
				animation-delay: -1.0s;
			}
		}
	}

	.error {
		font-family: $sans-serif-stack;
		font-size: 14px;
		display: block;
		overflow: hidden;
		color: var(--colour-login-error, red);
		transition: height .3s ease;
	}

}

@media (max-width: 600px) {
	.login {
		
		img.logo {
			margin-bottom: 2em;
		}
		
		input {
			width: 20px;
			padding: 10px;
			// font-size: 24px;
		}

	}
}

@-webkit-keyframes sk-bounce {
	0%, 100% { -webkit-transform: scale(0.0) }
	50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
	0%, 100% { 
		transform: scale(0.0);
		-webkit-transform: scale(0.0);
	} 50% { 
		transform: scale(1.0);
		-webkit-transform: scale(1.0);
	}
}

.fineprint {
	// position: fixed;
	bottom: 0;
	left: 0;
	box-sizing: border-box;
	width: 100%;
	max-width: 100%;
	text-align: center;
	padding: 10px;
	font-family: $sans-serif-stack;
	font-weight: 300;
	font-size: 10px;
}

/* ============================================================================== */
/* ============================================================================== */
/* SURVEY ======================================================================= */
/* ============================================================================== */

.survey {
	box-sizing: border-box;
	padding: 0;
	background-color: transparent;
	
	grid-column: 1 / 2;
	grid-row: 1 / 2;
	// justify-self: center;

	width: 100%;
	max-width: 100%;

	height: var(--vh);
	position: fixed;
	top: 0;

	iframe {
		height: var(--vh);
		min-height: var(--vh);
		max-height: var(--vh);
	}
}

@media (max-width: 500px) {
	.survey {
		iframe {
			left: 0 !important;
		}
	}
}

/* ============================================================================== */
/* ============================================================================== */
/* ENDSCREEN ==================================================================== */
/* ============================================================================== */

.endscreen {

	text-align: center;
	max-width: calc(100% - 2rem);
	justify-self: center;

	h1 {
		display: block;
		font-family: $sans-serif-stack;
		font-weight: 700;
		font-size: 3rem;
		margin-bottom: 1rem;
		line-height: 3rem;
	}

	h2 {
		display: block;
		font-family: $sans-serif-stack;
		font-weight: 700;
		font-size: 1.4rem;
		margin: 0 0 40px 0;
	}

	p {
		display: block;
		margin: 0 0 10px 0;
		max-width: 100%;
	}

	a {
		color: var(--colour-text, #000);
	}
	
	code {
		display: inline-block;
		background-color: var(--colour-thankyou-telephone-background, #ffffff44);
		text-decoration: none;
		color: #000 !important;
		padding: 0 3px;
		font-family: $monospace-stack;
		font-weight: 700;
	}

	img.logo {
		display: block;
		width: calc(100% - 4rem);
		max-width: 240px;
		margin: 4em auto 0 auto;
	}


}

@media (max-width: 600px) {
	.endscreen {

		h1 {
			font-size: 2rem;
			line-height: 2rem;
			margin-bottom: .2rem;
		}

		h2 {
			font-size: 1.2rem;
			line-height: 1.4rem;
			margin-bottom: 20px;
		}
		
		img.logo {
			margin-top: 2em;
			max-height: 100px;
		}

	}
}

/* ============================================================================== */
/* ============================================================================== */
/* CUSTOM BUILT DROPDOWN ======================================================== */
/* ============================================================================== */

.curtain {
	display: block;
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: #ffffff00;
	// -webkit-backdrop-filter: blur(3px);
	// backdrop-filter: blur(3px);
}

.select {

	position: relative;
	width: 100%;
	height: 100%;

	.input {
		display: grid;
		grid-template-columns: 1fr auto auto;

		box-sizing: border-box;
		height: 100%;

		background-color: #fcfcfc;
		cursor: pointer;

		.selected {
			display: grid;
			align-items: center;
			padding: var(--h) var(--p);
			font-size: 14px;
			word-break: break-all;
		}

		.multiselected {

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			align-items: center;
			padding: 0 var(--h) var(--h) 0;

			.value {
				display: grid;
				grid-template-columns: auto auto;
				background-color: var(--c-highlight);
				margin: var(--h) 0 0 var(--h);

				border-radius: var(--h);

				.label {
					padding: var(--q) var(--h);
					font-size: .8rem;
					cursor: default;
				}

				.unselect {
					padding: var(--q) var(--h);
					display: grid;
					align-items: center;
					border-left: 1px solid #00000022;
					color: #00000044;

					&:hover {
						color: black;
					}
				}
			}

		}

		.icon {
			padding: var(--h);
			display: grid;
			align-items: center;

			color: var(--c-muted);

			&:hover, &.black {
				color: black;
			}
		}
	}

	.dropdown {
		display: block;
		position: absolute;
		width: 100%;

		overflow-x: hidden;
		overflow-y: hidden;

		

		z-index: 1;

		background-color: #fff;
		border: 1px solid #bcbcbc;

		font-size: 14px;

		input {
			display: block;
			padding: var(--p) !important;
			border: 0;
			width: 100%;
			border-bottom: 1px solid #bcbcbc !important;
			background-color: #fff !important;

		}

		.options {
			max-height: 200px;
			overflow-y: scroll;

			.heading {
				padding: var(--h) var(--p);
				background-color: var(--c-boundary);
				font-weight: 700;
			}

			.option {
				padding: var(--p);

				&:hover {
					background-color: var(--c-boundary);
					cursor: pointer;
				}

				&.active {
					background-color: var(--c-highlight);
				}

				&.disabled {
					color: var(--c-muted);
					cursor: default;

					&:hover {
						background-color: transparent;
					}
				}
			}
		}


	}

}

.field-box > .select .input {
	background-color: var(--c-boundary);
}

.translation {
	position: fixed;
	width: 210px;
	top: 20px;
	right: 20px;
	padding: 0;
	font-family: var(--sans-serif-stack, sans-serif);

	.select {
		.input {
			border-radius: 5px;
			overflow: hidden;
			background-color: var(--colour-login-input, #fff);

			.selected {
				padding: 5px 10px;
			}

			.icon {
				padding: 5px;
			}
		}

		.dropdown {
			box-sizing: border-box;
			margin-top: 5px;
			border: none;
			border-radius: 5px;
			background-color: var(--colour-login-input, #fff);

			.options {
				overflow-y: hidden;

				.option {
					padding: 5px 10px;

					&.active {
						display: none;
					}

					&:hover {
						background-color: #00000011
					}
				}
			}
		}

	}
}