html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--colour-main, #efefef);
  background-image: var(--image-background, none);
  background-size: var(--background-size, 100%);
  background-repeat: var(--background-repeat, repeat-y);
  background-position: var(--background-position, 0 -100px);
  font-family: var(--serif-stack, serif);
  color: var(--colour-text, #000);
  min-height: var(--vh);
  max-height: var(--vh);
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 16px;
  overflow: hidden auto;
}

body {
  max-height: var(--vh);
  min-height: var(--vh);
  border: 0;
  grid-template-rows: 1fr auto;
  width: 100%;
  margin: 0;
  padding: 0;
  display: grid;
}

.hide, .hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

main {
  max-height: auto;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: auto;
  display: grid;
}

.login {
  text-align: center;
  justify-self: center;
  max-width: calc(100% - 2rem);
}

.login img.logo {
  width: calc(100% - 4rem);
  max-width: 320px;
  margin: 0 auto 4em;
  display: block;
}

.login .prompt {
  font-family: var(--sans-serif-stack, sans-serif);
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-size: 14px;
  display: inline-block;
}

.login input {
  font-size: 32px;
  font-family: var(--monospace-stack, monospace);
  text-align: center;
  opacity: .8;
  text-transform: uppercase;
  background-color: var(--colour-login-input, #fff);
  border: 0;
  width: 34px;
  margin: 0;
  padding: 10px;
  font-weight: 700;
  display: inline-block;
  z-index: -1 !important;
}

.login input:focus {
  opacity: 1;
  outline: none;
}

.login input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.login .loader {
  background-color: var(--colour-main-translucent, #efefef99);
  width: 100%;
  margin: -120px auto 0;
  padding: 30px 0;
  display: none;
  position: relative;
  z-index: 99 !important;
}

.login .loader .elements {
  width: 40px;
  height: 40px;
  margin: 20px auto;
  position: relative;
}

.login .loader .elements .circle1, .login .loader .elements .circle2 {
  opacity: .6;
  background-color: #fff;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: 2s ease-in-out infinite sk-bounce;
  position: absolute;
  top: 0;
  left: 0;
}

.login .loader .elements .circle2 {
  animation-delay: -1s;
}

.login .error {
  font-family: var(--sans-serif-stack, sans-serif);
  color: var(--colour-login-error, red);
  font-size: 14px;
  transition: height .3s;
  display: block;
  overflow: hidden;
}

@media (width <= 600px) {
  .login img.logo {
    margin-bottom: 2em;
  }

  .login input {
    width: 20px;
    padding: 10px;
  }
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.fineprint {
  box-sizing: border-box;
  text-align: center;
  font-family: var(--sans-serif-stack, sans-serif);
  width: 100%;
  max-width: 100%;
  padding: 10px;
  font-size: 10px;
  font-weight: 300;
  bottom: 0;
  left: 0;
}

.survey {
  box-sizing: border-box;
  height: var(--vh);
  background-color: #0000;
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  max-width: 100%;
  padding: 0;
  position: fixed;
  top: 0;
}

.survey iframe {
  height: var(--vh);
  min-height: var(--vh);
  max-height: var(--vh);
}

@media (width <= 500px) {
  .survey iframe {
    left: 0 !important;
  }
}

.endscreen {
  text-align: center;
  justify-self: center;
  max-width: calc(100% - 2rem);
}

.endscreen h1 {
  font-family: var(--sans-serif-stack, sans-serif);
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3rem;
  display: block;
}

.endscreen h2 {
  font-family: var(--sans-serif-stack, sans-serif);
  margin: 0 0 40px;
  font-size: 1.4rem;
  font-weight: 700;
  display: block;
}

.endscreen p {
  max-width: 100%;
  margin: 0 0 10px;
  display: block;
}

.endscreen a {
  color: var(--colour-text, #000);
}

.endscreen code {
  background-color: var(--colour-thankyou-telephone-background, #fff4);
  font-family: var(--monospace-stack, monospace);
  padding: 0 3px;
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  color: #000 !important;
}

.endscreen img.logo {
  width: calc(100% - 4rem);
  max-width: 240px;
  margin: 4em auto 0;
  display: block;
}

@media (width <= 600px) {
  .endscreen h1 {
    margin-bottom: .2rem;
    font-size: 2rem;
    line-height: 2rem;
  }

  .endscreen h2 {
    margin-bottom: 20px;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .endscreen img.logo {
    max-height: 100px;
    margin-top: 2em;
  }
}

.curtain {
  background-color: #fff0;
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.select {
  width: 100%;
  height: 100%;
  position: relative;
}

.select .input {
  box-sizing: border-box;
  cursor: pointer;
  background-color: #fcfcfc;
  grid-template-columns: 1fr auto auto;
  height: 100%;
  display: grid;
}

.select .input .selected {
  padding: var(--h) var(--p);
  word-break: break-all;
  align-items: center;
  font-size: 14px;
  display: grid;
}

.select .input .multiselected {
  padding: 0 var(--h) var(--h) 0;
  flex-flow: wrap;
  align-items: center;
  display: flex;
}

.select .input .multiselected .value {
  background-color: var(--c-highlight);
  margin: var(--h) 0 0 var(--h);
  border-radius: var(--h);
  grid-template-columns: auto auto;
  display: grid;
}

.select .input .multiselected .value .label {
  padding: var(--q) var(--h);
  cursor: default;
  font-size: .8rem;
}

.select .input .multiselected .value .unselect {
  padding: var(--q) var(--h);
  color: #0004;
  border-left: 1px solid #0002;
  align-items: center;
  display: grid;
}

.select .input .multiselected .value .unselect:hover {
  color: #000;
}

.select .input .icon {
  padding: var(--h);
  color: var(--c-muted);
  align-items: center;
  display: grid;
}

.select .input .icon:hover, .select .input .icon.black {
  color: #000;
}

.select .dropdown {
  z-index: 1;
  background-color: #fff;
  border: 1px solid #bcbcbc;
  width: 100%;
  font-size: 14px;
  display: block;
  position: absolute;
  overflow: hidden;
}

.select .dropdown input {
  border: 0;
  width: 100%;
  display: block;
  padding: var(--p) !important;
  background-color: #fff !important;
  border-bottom: 1px solid #bcbcbc !important;
}

.select .dropdown .options {
  max-height: 200px;
  overflow-y: scroll;
}

.select .dropdown .options .heading {
  padding: var(--h) var(--p);
  background-color: var(--c-boundary);
  font-weight: 700;
}

.select .dropdown .options .option {
  padding: var(--p);
}

.select .dropdown .options .option:hover {
  background-color: var(--c-boundary);
  cursor: pointer;
}

.select .dropdown .options .option.active {
  background-color: var(--c-highlight);
}

.select .dropdown .options .option.disabled {
  color: var(--c-muted);
  cursor: default;
}

.select .dropdown .options .option.disabled:hover {
  background-color: #0000;
}

.field-box > .select .input {
  background-color: var(--c-boundary);
}

.translation {
  font-family: var(--sans-serif-stack, sans-serif);
  width: 210px;
  padding: 0;
  position: fixed;
  top: 20px;
  right: 20px;
}

.translation .select .input {
  background-color: var(--colour-login-input, #fff);
  border-radius: 5px;
  overflow: hidden;
}

.translation .select .input .selected {
  padding: 5px 10px;
}

.translation .select .input .icon {
  padding: 5px;
}

.translation .select .dropdown {
  box-sizing: border-box;
  background-color: var(--colour-login-input, #fff);
  border: none;
  border-radius: 5px;
  margin-top: 5px;
}

.translation .select .dropdown .options {
  overflow-y: hidden;
}

.translation .select .dropdown .options .option {
  padding: 5px 10px;
}

.translation .select .dropdown .options .option.active {
  display: none;
}

.translation .select .dropdown .options .option:hover {
  background-color: #0001;
}

/*# sourceMappingURL=index.4a84ac72.css.map */
